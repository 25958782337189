@import url("./homepage.css");
@import url("./admin.css");
/*
  ShipThis.cc Styles

  Media Queries:
  @media (min-width: 576px) { }
  @media (min-width: 768px) { }

  Mobile first.

  LA BEM

  .button {}            Block component
  .button--primary {}   Modifier
  .button-icon {}       Child Element within block
  .button-icon--md {}   Child modifier
  .button-icon--lg {}

  Z-Index
    1000: Feedback Widget
    2000: PopUp
*/
:root {
  --border-radius: 8px;
  --border-radius-lg: 12px;
  --font-default: "Lexend Deca";
  --font-mono: "Roboto Mono", monospace;
  --hover-transition-time: 150ms;
}

:root {
  --colors-primary: #f5567c;
  --colors-primary--hover: #ed335f;
  --colors-secondary: #615a8296;
  --colors-green: #65ffa2; /* #00ff57 */
  --colors-red: #ef4444; /* ff4800 */
  --colors-blue: #445fef;
  --colors-white: #ffffff;
  --colors-black: #000000;
  --colors-grey100: #f1f2f4;
  --colors-grey200: #e3e6e8;
  --colors-grey300: #afb8bf;
  --colors-grey400: #a4bfcc; /* #95a0aa */
  --colors-grey500: #7b8995;
  --colors-grey600: #627280;
  --colors-grey700: #485b6c;
  --colors-grey800: #142d42;
  --colors-grey900: #131927;
  --colors-grey950: #0d121b;
  --colors-admin-section: #111928;
  --colors-admin-selected: #b0f4ff; /* #9fe0ff */
  --colors-admin-section--hover: #1d283d;
  --colors-admin-section--darker: #090e1a;
  --color-link: #008fd4;
}

html {
  font-size: 62.5%;
  background: var(--colors-black);
}

body {
  margin: 0;
  font-family: var(--font-default), -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.6em;
  font-optical-sizing: auto;
  overflow-x: hidden;
}

code {
  font-family: "Roboto Mono", monospace;
}

/* HELPERS --------------------------------------------------------- */

.lockScroll {
  overflow: hidden;
}

/* TYPE ------------------------------------------------------------ */

h1 {
  font-size: 4.2rem;
}

h2 {
  font-size: 2.4rem;
  font-weight: 600;
}

h3 {
  font-size: 1.8rem;
  font-weight: 400;
}
